$rwth-blue: rgb(9, 84, 159);
$rwth-blue-75: rgb(64, 127, 183);
$rwth-blue-50: rgb(142, 186, 229);
$rwth-blue-25: rgb(199, 221, 242);
$rwth-blue-10: rgb(232, 241, 250);

$rwth-black: rgb(0, 0, 0);
$rwth-black-75: rgb(100, 101, 103);
$rwth-black-50: rgb(156, 158, 159);
$rwth-black-25: rgb(207, 209, 210);
$rwth-black-10: rgb(236, 237, 237);

$rwth-green: rgb(87, 171, 39);
$rwth-green-75: rgb(141, 192, 96);
$rwth-green-50: rgb(184, 214, 152);
$rwth-green-25: rgb(221, 235, 206);
$rwth-green-10: rgb(242, 247, 236);

$rwth-maygreen: rgb(189, 205, 0);
$rwth-maygreen-75: rgb(208, 217, 92);
$rwth-maygreen-50: rgb(224, 230, 154);
$rwth-maygreen-25: rgb(240, 243, 208);
$rwth-maygreen-10: rgb(249, 250, 237);

$rwth-orange: rgb(246, 168, 0);
$rwth-orange-75: rgb(250, 190, 80);
$rwth-orange-50: rgb(253, 212, 143);
$rwth-orange-25: rgb(254, 234, 201);
$rwth-orange-10: rgb(255, 247, 234);

$rwth-red: rgb(204, 7, 30);
$rwth-red-75: rgb(216, 92, 65);
$rwth-red-50: rgb(230, 150, 121);
$rwth-red-25: rgb(243, 205, 187);
$rwth-red-10: rgb(250, 235, 227);

:export {
	rwthBlue: $rwth-blue;
	rwthBlue75: $rwth-blue-75;
	rwthBlue50: $rwth-blue-50;
	rwthBlue25: $rwth-blue-25;
	rwthBlue10: $rwth-blue-10;

	rwthMaygreen: $rwth-maygreen;
	rwthMaygreen75: $rwth-maygreen-75;
	rwthMaygreen50: $rwth-maygreen-50;
	rwthMaygreen25: $rwth-maygreen-25;
	rwthMaygreen10: $rwth-maygreen-10;

	rwthOrange: $rwth-orange;
	rwthOrange75: $rwth-orange-75;
	rwthOrange50: $rwth-orange-50;
	rwthOrange25: $rwth-orange-25;
	rwthOrange10: $rwth-orange-10;

	rwthRed: $rwth-red;
	rwthRed75: $rwth-red-75;
	rwthRed50: $rwth-red-50;
	rwthRed25: $rwth-red-25;
	rwthRed10: $rwth-red-10;
};