@import "./rwth.colors.module.scss";

// Include any default variable overrides here (though functions won't be available)

$primary: $rwth-blue;
$secondary: $rwth-black;
$danger: $rwth-red;
$success: $rwth-green;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger
);

@import "~bootstrap/scss/bootstrap";

// Then add additional custom code here

html, body, #root {
	height: 100%;
}

#root {
	display: flex;
	flex-direction: column;
}